import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import {
  Branch,
  ConfigmanagerConflictResolution,
  ConfigManagerDryMergeResponse,
  ConfigManagerMergeResponse,
  GetBranchesResponse,
} from './api-v2.model';
import { catchError, map, Observable, of } from 'rxjs';
import { Author } from '@app/shared/author/author';
import { HttpClient } from '@angular/common/http';
import { Project, ProjectService } from '@app/providers/projects';
import { NotificationMessageService } from '@app/shared/notification-message/notification-message.service';

@Injectable()
export class ConfigManagerV2Service {
  private project: Project;
  private readonly origin = environment.configManagerV2Api;

  constructor(
    protected http: HttpClient,
    private service: ProjectService,
    private message: NotificationMessageService,
  ) {
    this.service.project$.subscribe((project: Project) => {
      this.project = project;
    });
  }

  protected getPath(): string {
    return `${this.origin}/products/${this.project.short_name_cm}`;
  }

  getBranches(): Observable<Branch[]> {
    return this.http
      .get<GetBranchesResponse>(`${this.getPath()}/branches`)
      .pipe(
        map(({ branches }) =>
          branches.map((branch) =>
            Object.assign(branch, { owner: Author.clean(branch.owner) }),
          ),
        ),
        catchError(this.handleError('getBranches', [])),
      );
  }

  dryMerge(
    local: string,
    remote: string,
    resolutions: ConfigmanagerConflictResolution[],
  ): Observable<ConfigManagerDryMergeResponse> {
    return this.http.patch<ConfigManagerDryMergeResponse>(
      `${this.getPath()}/branches/${local}/dry_merge/${remote}`,
      { resolutions },
    );
  }

  merge(
    local: string,
    remote: string,
    resolutions: ConfigmanagerConflictResolution[],
  ): Observable<ConfigManagerMergeResponse> {
    return this.http.patch<ConfigManagerMergeResponse>(
      `${this.getPath()}/branches/${local}/merge/${remote}`,
      { resolutions },
    );
  }

  private handleError<T>(_operation = 'operation', result?: T) {
    return ({ error }: { error: any }): Observable<T> => {
      console.error(error);
      this.message.open({
        type: 'error',
        html: (error && error.message) || 'Error',
      });
      return of(result as T);
    };
  }
}
