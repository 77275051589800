import { ErrorHandler, NgModule } from '@angular/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';

import { GoogleAnalyticsService } from '@app/providers/google/analytics/google-analytics.service';
import { ProjectService } from '@app/providers/projects';
import { Api, SentryErrorHandler } from '@app/providers';
import { GoogleApiModule } from '@app/providers/google/api/google-api.module';
import { AuthModule } from '@app/providers/auth/auth.module';
import { ReleaseControlModule } from '@app/providers/release-control/release-control.module';
import { auth, github } from '@env/environment';
import { GithubModule } from '@app/providers/github/github.module';
import { SessionModule } from '@app/providers/session/session.module';
import { WindowModule } from '@app/providers/window/window.module';
import { CheckerModule, GithubCheckerPlugin } from '@app/providers/checker';
import { ProxyService } from '@app/providers/proxy';

@NgModule({
  imports: [
    AuthModule.forRoot(auth),
    SessionModule.forRoot(),
    GithubModule.forRoot(github),
    CheckerModule.forRoot(GithubCheckerPlugin.GlobalChecks({ id: 'status' })),
    GoogleApiModule.forRoot(),
    ReleaseControlModule.forRoot(),
    WindowModule.forRoot(),
  ],
  providers: [
    Api,
    ProjectService,
    GoogleAnalyticsService,
    ProxyService,
    {
      provide: ErrorHandler,
      useClass: SentryErrorHandler,
    },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
  ],
})
export class CoreModule {}
