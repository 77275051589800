import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Project, ProjectService } from '@app/providers/projects';
import { PROJECT_HAS_PROXY } from './proxy.model';

@Injectable()
export class ProxyService {
  private project: Project;

  constructor(
    private http: HttpClient,
    private projectService: ProjectService,
  ) {
    this.projectService.project$.subscribe(
      (project: Project) => (this.project = project),
    );
  }

  getUrl(): string {
    const validProject = PROJECT_HAS_PROXY.has(this.project.short_name)
      ? this.project.short_name
      : 'sg';
    return `https://${validProject}-toolboxproxy.socialpointgames.com/forward`;
  }

  getRequest(method: string = 'GET', url: string, body?): Observable<any> {
    return this.http.post<any>(this.getUrl(), {
      method,
      url,
      body: body ? JSON.stringify(body) : null,
    });
  }
}
