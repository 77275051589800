export const PROJECT_HAS_PROXY = new Set([
  'sg',
  'cp',
  'rc',
  'sem',
  'wl',
  'dc',
  'mc',
  'twd',
  'tr',
  'thd',
  'rt',
]);
