import { Routes } from '@angular/router';
import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared';
import { LoginComponent } from './login.component';
import { NgOptimizedImage } from '@angular/common';
import { UnauthorizedComponent, UnauthorizedResolver } from './../unauthorized';

export const loginRoutes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent,
    resolve: {
      unauthorized: UnauthorizedResolver,
    },
  },
];

@NgModule({
  imports: [SharedModule, NgOptimizedImage],
  declarations: [LoginComponent, UnauthorizedComponent],
  exports: [LoginComponent, UnauthorizedComponent],
  providers: [UnauthorizedResolver],
})
export class LoginModule {}
