import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  Resolve,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { Api } from '../api/api';
import { Project } from './project.model';
import { ProjectService } from './project.service';
import gameIcons from '../../../game-icons.json';

@Injectable()
export class ProjectAllResolver implements Resolve<Observable<any>> {
  constructor(
    private api: Api,
    private router: Router,
    private route: ActivatedRoute,
    private projectService: ProjectService,
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<any> {
    const projectsArray = this.projectService.getProjects();
    if (!projectsArray) {
      return this.api
        .rpc({
          repository: 'projects',
          handler: 'all',
          method: 'get',
        })
        .pipe(
          catchError((error: any) => {
            if (error.status === 401) {
              this.router.navigate(['/unauthorized'], {
                relativeTo: this.route,
              });
            }

            // TODO: put maintenance routing in Api service
            if (!error.status) {
              this.router.navigate(['/maintenance'], {
                relativeTo: this.route,
              });
            }
            return of(error);
          }),
          switchMap((projects: Project[]) =>
            this.projectService.setProjects(projects, gameIcons),
          ),
        );
    }

    return of(projectsArray);
  }
}

@Injectable()
export class ProjectResolver implements Resolve<Observable<any>> {
  constructor(
    private api: Api,
    private router: Router,
    private route: ActivatedRoute,
    private projectService: ProjectService,
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<Project> {
    const projectsArray = this.projectService.getProjects();
    const project = projectsArray.find(
      (proj) => proj.alias === route.params['alias'],
    );
    if (project) {
      this.projectService.setProject(project);
      return of(project);
    }
    this.router.navigate(['/projects']);
  }
}
