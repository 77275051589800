import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { UntypedFormGroup } from '@angular/forms';
import { EMPTY, from, Observable, of } from 'rxjs';
import {
  catchError,
  concatMap,
  expand,
  map,
  retry,
  share,
  switchMap,
  toArray,
} from 'rxjs/operators';
import * as moment from 'moment';

import { Project, ProjectService } from '@app/providers/projects';
import { NotificationMessageService } from '@app/shared/notification-message/notification-message.service';
import {
  Pod,
  CUSTOM_PROJECTS_DATA,
  ProjectOverwriteServices,
  OverwriteService,
} from './dynamic-environment-api.model';
import { Api } from '@app/providers/api';
import { AuthService } from '@app/providers/auth/auth.service';
import { GithubService } from '@app/providers/github/github.service';
import { ProxyService } from '@app/providers/proxy';

@Injectable()
export class DynamicEnvironmentService {
  private readonly base = 'https://dynapi-internal.dev.socialpointgames.com';
  private readonly path = Api.getUrl({
    repository: 'github',
    handler: 'query',
  });
  private project: Project;

  static parseData(environmentForm: UntypedFormGroup): any {
    const data = environmentForm.value;
    data.expiration = moment(environmentForm.get('expiration').value).unix();

    // Remove property if is null
    if (!data.linkage) {
      delete data.linkage;
    }

    return data;
  }

  static next(link: string): string | null {
    let url: string | null = null;
    if (link) {
      const match = link.match(/<([^>]+)>;\s*rel="next"/);
      if (match) {
        [, url] = match;
      }
    }

    return url;
  }

  constructor(
    private http: HttpClient,
    private service: ProjectService,
    private message: NotificationMessageService,
    private auth: AuthService,
    private github: GithubService,
    private proxy: ProxyService,
  ) {
    this.service.project$.subscribe(
      (project: Project) => (this.project = project),
    );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return ({ error }: { error: any }): Observable<T> => {
      console.error(error);
      this.message.open({
        type: 'error',
        text:
          (error && error.message) ||
          'Server not responding.\nRunning Charles? Quit & try again\nUsing Adblock? Disable & try again\nNone of the above? Contact us on our slack channel: #stf-support-backend',
      });
      return of(result as T);
    };
  }

  getPath(): string {
    return `${this.base}/environments`;
  }

  getProjectUrl(): string {
    return `${this.getPath()}/${this.project.short_name}`;
  }

  getEnvironment(environment: string): Observable<Pod[]> {
    return this.proxy
      .getRequest('GET', `${this.getProjectUrl()}/${environment}`)
      .pipe(
        map((data: any) => Object.keys(data).map((env) => data[env])),
        catchError(this.handleError<Pod[]>('getEnvironment', [])),
      );
  }

  getEnvironments(): Observable<Pod[]> {
    return this.proxy.getRequest('GET', this.getProjectUrl()).pipe(
      map((data): Pod[] =>
        Object.keys(data).map((environment) => data[environment]),
      ),
      retry(2),
      catchError(this.handleError('getEnvironments', [])),
    );
  }

  deleteEnvironment(environment: string): Observable<{}> {
    return this.proxy
      .getRequest('DELETE', `${this.getProjectUrl()}/${environment}`)
      .pipe(catchError(this.handleError('deleteEnvironment')));
  }

  getBranches(repository: string): Observable<string[]> {
    return this.getBranchesPage(repository).pipe(
      expand(({ next }) =>
        next ? this.getBranchesPage(repository, next) : EMPTY,
      ),
      concatMap(({ content }) => content),
      toArray(),
      share(),
      catchError(this.handleError('getBranches', [])),
    );
  }

  addEnvironment(data): Observable<{}> {
    return this.proxy
      .getRequest('POST', this.getPath(), {
        ...data,
        project: this.project.short_name,
      })
      .pipe(catchError(this.handleError('addEnvironment')));
  }

  editEnvironment(data, id): Observable<{}> {
    return this.proxy
      .getRequest('PATCH', `${this.getProjectUrl()}/${id}`, {
        ...data,
        project: this.project.short_name,
        user: this.auth.profile.email,
      })
      .pipe(catchError(this.handleError('editEnvironment')));
  }

  getProjectData(data, config): Observable<any> {
    if (!CUSTOM_PROJECTS_DATA.has(this.project.short_name)) return of(data);

    return of(
      Object.assign(
        {},
        data,
        CUSTOM_PROJECTS_DATA.get(this.project.short_name),
        { chart_version: `0.0.0-${this.dirifyBranch(data.branch)}` },
      ),
    );
  }

  getOverwriteServices(): OverwriteService[] {
    if (ProjectOverwriteServices.has(this.project.short_name)) {
      return ProjectOverwriteServices.get(this.project.short_name);
    }

    return [];
  }

  private getBranchesPage(
    repository: string,
    query: string = '',
  ): Observable<{
    content: any[];
    next: string | null;
  }> {
    const params = new HttpParams({ fromString: query.split('?')[1] })
      .set('path', `/${repository}/branches`)
      .set('project', `${this.project.id}`);

    return from(this.github.getToken()).pipe(
      switchMap((token) =>
        this.http.get<{ name: string }[]>(this.path, {
          params,
          observe: 'response',
          headers: { 'X-Github-Token': token },
        }),
      ),
      map(({ body, headers: head }) => ({
        content: body.map(({ name }) => name),
        next: DynamicEnvironmentService.next(head.get('Link')),
      })),
    );
  }

  private dirifyBranch(branch: string): string {
    return branch.replace(/[^-0-9a-z]/gi, '-');
  }

  private parseManifest(manifestData: string): any {
    const rowRegExp = /^\s*(?<k>[^:]+):(?<v>.+)\s*$/gm;
    const output = {} as any;

    let match;
    while ((match = rowRegExp.exec(manifestData))) {
      const { k, v } = match.groups;
      output[k] = v;
    }

    return output;
  }
}
