import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss'],
})
export class UnauthorizedComponent {
  loading = false;
  authErrors: string[];

  constructor(private router: Router, private route: ActivatedRoute) {
    if (!Array.isArray(this.route.snapshot.data['unauthorized'])) return;

    this.router.navigate(['/projects']);
  }
}
