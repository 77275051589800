import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Resolve,
  Router,
  ActivatedRoute,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { Api } from '@app/providers/api/api';

@Injectable()
export class MaintenanceResolver implements Resolve<Observable<Response>> {
  constructor(
    private api: Api,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<Response> {
    return this.api
      .rpc({
        repository: 'projects',
        handler: 'all',
      })
      .pipe(
        catchError((error: Response) => {
          if (error.status === 401) {
            console.log(route);
            this.router.navigate(['/unauthorized'], {
              relativeTo: this.route,
            });
          }
          return of(error);
        }),
      );
  }
}
